import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { useSiteState } from '../lib/siteContext'

import { QueryFragments } from '../GraphQl/queryFragments' // eslint-disable-line

// Components
import { PageMeta } from '../components/pageMeta'
import ModuleZone from '~moduleZone'

const PageTemplate = ({ data: { page } }) => {
  const { seo, pageModules, config, pageNav } = page || {}
  const { bgColor, logoColor } = config || {}
  const { homeHeader, setHomeHeader, setLogoColor } = useSiteState()

  useEffect(() => {
    if (homeHeader === true) {
      setHomeHeader(false)
    }
    if (logoColor) {
      document.documentElement.style.setProperty(
        `--color-logo`,
        logoColor.value
      )
    }

    if (bgColor.value) {
      document.documentElement.style.setProperty(`--doc-bg`, bgColor.value)
    }
  }, [setHomeHeader, homeHeader, setLogoColor, bgColor, logoColor])

  return (
    <>
      <PageMeta {...seo} />
      {pageNav && (
        <div className="page-nav z-20 absolute lg:fixed top-60 lg:top-screen-half lg:transform lg:-translate-y-full right-gutter text-doc-text flex flex-col heading uppercase">
          {pageNav.map((item, index) => (
            <AnchorLink
              className="mb-2 trans-std"
              to={`#${item.target}`}
              title="Our team"
            >
              - {item.linkText}
            </AnchorLink>
          ))}
        </div>
      )}
      {pageModules && <ModuleZone {...pageModules} />}
    </>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      pageNav {
        target
        linkText
      }
      pageModules: modules {
        ...PageModules
      }
      config {
        logoColor {
          title
          value
        }
        bgColor {
          value
          title
        }
      }
    }
  }
`
